import React from 'react'
import {graphql, useStaticQuery} from 'gatsby'
import { get } from 'lodash'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { usStates } from '../helpers.js'
import styles from '../scss/page.scss'
import axios from 'axios';
import qs from 'qs';
import Section from "../components/section/section";
import ModalCustom from "../components/modals/modal-custom.js";

class Careers extends React.Component {
  constructor(props) {
    super(props)
    this.modalCustom = React.createRef()
    const title = 'Let’s work together - Careers at Therapetic'
    this.seo = {
      title: title,
      description: '',
      seoTitle: title,
      children: [
        <link rel="canonical" href='https://therapetic.org/careers' key='canonical-url'  />
      ]
    }
    this.state = {
      full_name: '',
      email: '',
      message: '',
      msg: '',
      errors: {}
    }

    this.handleInputChange = this.handleInputChange.bind(this)
    this.submit = this.submit.bind(this)
  }
  showModal(modal) {
    let modalName = `modal` + modal.substring(0, 1).toUpperCase() + modal.substring(1)
    if (modalName in this) {
      this[modalName].current.showModal()
    }
  }
  addNotify (msg) {
    if(msg === 200) {
      this.setState({
        msg: "We will contact you soon! Thanks."
      })
    } else {
      this.setState({
        msg: "An error occurred. Please contact our support."
      })
    }
  }
  resetForm() {
    this.setState({
      full_name: '',
      email: '',
      message: '',
      submitting: false,
      errors: {}
    });
  }
  /**
   * Handles inputs changes
   * @param  {Event} e
   * @return {void}
   */
  handleInputChange(e) {
    const target = e.currentTarget
    const value = target.value
    const stateProp = target.name
    this.setState({
      [stateProp]: value
    })
  }

  /**
   * Submits the form
   * @param  {Event} e
   * @return {void}
   */
  submit(e) {
    e.preventDefault()
    const form = e.currentTarget
    this.setState({
      errors: {},
      submitting: true
    }, () => {
      let errors = {}
      const requiredFields = [
        'full_name',
        'email',
        'message',
      ]
      requiredFields.forEach((field) => {
        if (!this.state[field]) {
          errors[field] = true
        }
      })
      if (Object.keys(errors).length) {
        this.setState({
          errors,
          submitting: false
        })
      } else {
        const params = {}
        for (const element of form.elements) {
          if (element.name) {
            params[element.name] = element.value
          }
        }
        const options = {
          method: 'POST',
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
          data: qs.stringify(params),
          url: form.action,
        };
        axios(options).then(response => {
          e.preventDefault();
          this.showModal('Custom')
          this.addNotify(response.status)
          this.resetForm()
        })
          .catch(error => {
            e.preventDefault();
            this.showModal('Custom')
            this.addNotify(error)
            this.resetForm()
          });
      }
    })
  }

  render() {
    return (
      <Layout containerClassNames={`page page-careers`}>
        <SEO { ...this.seo } />
        <div className="page-header-top-placeholder"></div>
        <Section className="careers py-2 px-0">
          <div className="row">
            <div className='col-10 col-md-6 mt-5 mx-auto my-md-5'>
              <h1 className="text-lg-center text-md-left">Let’s work together - Careers at Therapetic</h1>
              <br/>
              <p>
                Like you, Therapetic knows how much of an effect pets can have in our lives. Even more so when they are our emotional support animals. As a company focused on ESAs, our mission is to connect those in need to licensed mental health professionals in their area for an emotional support animal letter.
              </p>
              <br/>
              <p>That’s why our vision is simple: real therapists providing real comfort so real people can live a better life.</p>
              <p>Consisting of hard-working, talented individuals, Therapetic looks for like-minded people who share our vision to change the lives of so many, one ESA letter at a time.</p>
              <br/>
              <h2>Teams:</h2>
              <ul>
                <li>Marketing</li>
                <li><a href="/counselor-jobs">Counselor/Licensed Mental Health Professionals</a></li>
                <li>Customer Experience</li>
                <li>UI/UX</li>
              </ul>
              <br/>
              <h2>Benefits:</h2>
              <ul>
                <li>Unlimited Vacation</li>
                <ul><li>No set numbers of days when it comes to vacation.</li></ul>
                <li>Professional Development</li>
                <ul><li>You’ll have access and the resources to grow in our company.</li></ul>
                <li>Health Insurance</li>
                <ul><li>For all those located in the U.S.</li></ul>
                <li>Competitive Salary</li>
                <ul><li>We understand the market and our compensation packages prove so.</li></ul>
                <li>Remote</li>
                <ul><li>You can work from the comfort of your home!</li></ul>
              </ul>
            </div>
          </div>
        </Section>
        <section className="bg-light signup-form">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-10 offset-md-1">
                <h2 className="text-primary mb-0 mt-4">Need more Information?</h2>
                <form onSubmit={this.submit} name="form-careers" method="POST" data-netlify="true" data-netlify-honeypot="bot-field">
                  <input type="hidden" name="form-name" value="form-careers" />
                  <input type="text" name="bot-field" className="d-none" /> {/* Input for preventing bots  */}
                  <input type="hidden" name="subject" value="Message sent from Careers | Therapetic" />
                  <div className="row my-4">
                    <div className="col-12 col-md-6 form-group">
                      <label htmlFor="full_name">Full Name</label>
                      <input type="text" className="form-control" id="full_name" name="full_name" value={ this.state.full_name } onChange={this.handleInputChange}/>
                      <small className={ `form-text text-danger ${this.state.errors.full_name ? '' : 'd-none'}` }>Please, enter your first name.</small>
                    </div>
                    <div className="col-12 col-md-6 form-group">
                      <label htmlFor="email">Email Address</label>
                      <input type="email" className="form-control" id="email" name="email" value={ this.state.email } onChange={this.handleInputChange}/>
                      <small className={ `form-text text-danger ${this.state.errors.email ? '' : 'd-none'}` }>Please, enter your email.</small>
                    </div>
                    <div className="col-12">
                      <label htmlFor="message">Message</label>
                      <textarea className="form-control" id="message" name="message" value={ this.state.message } onChange={this.handleInputChange}>
                      </textarea>
                      <small className={ `form-text text-danger ${this.state.errors.message ? '' : 'd-none'}` }>Please, enter a message.</small>
                    </div>
                    <div className="col-12 my-4">
                      <button type="submit" className="btn-cta" disabled={this.state.submitting}>SEND MESSAGE</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
        <ModalCustom ref={this.modalCustom} message={this.state.msg}/>
      </Layout>
    )
  }
}

export default (props) => {
  const data = useStaticQuery(graphql`
  query CareersPage {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }    
  }
`)
  return (
    <Careers  {...props} data={data}/>
  )
};